(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name referees.newReferee.controller:NewRefereeCtrl
   *
   * @description
   *
   */
  angular
  .module('neo.home.referees.newReferee')
  .controller('NewRefereeCtrl', NewRefereeCtrl);

  function NewRefereeCtrl(AclService, townHalls, entities, licenseTypes, genders, DuplicatedPerson, $mdDialog, $mdToast, PersonLicense, RefereeLicence, $state, sports,$filter) {
    var vm = this;
    vm.ctrlName = 'NewRefereeCtrl';
    vm.today = moment().toDate();
    vm.personGenders = _.filter(genders, 'person');
    vm.referee = undefined;
    vm.isAdmin = AclService.isAdmin;
    vm.townHalls = townHalls;
    vm.entities = entities;
    vm.setRefereeToCouncil = setRefereeToCouncil;
    vm.setRefereeToTownhall = setRefereeToTownhall;
    vm.setRefereeToEntity = setRefereeToEntity;
    vm.isTowhallSelectable = false;
    vm.isEntitySelectable = false;
    vm.belongsToCouncil = true;
    vm.belongsToTownHall = false;
    vm.belongsToEntity = false;
    vm.lopd = false;

    if (!vm.isAdmin()) {
      vm.isTowhallSelectable = true;
      if (vm.townHalls.length === 1) {
        vm.selectedTownhall = angular.copy(vm.townHalls[0]);
        vm.isTowhallSelectable = false;
      }
      vm.belongsToTownHall = true;
      vm.belongsToCouncil = false;
    }


    function setRefereeToCouncil() {
      vm.belongsToCouncil = true;
      vm.belongsToTownHall = false;
      vm.isTowhallSelectable = false;
      vm.selectedTownhall = undefined;
    }

    function setRefereeToTownhall() {
      vm.belongsToTownHall = true;
      vm.belongsToCouncil = false;
      vm.belongsToEntity = false;
      vm.isEntitySelectable = false;
      vm.selectedEntity = undefined;
      vm.isTowhallSelectable = true;
      if (angular.isDefined(vm.townHalls)) {
        if (vm.townHalls.length === 1) {
          vm.selectedTownhall = angular.copy(vm.townHalls[0]);
          vm.isTowhallSelectable = false;
        }
      }
    }

    function setRefereeToEntity() {
      vm.belongsToEntity = true;
      vm.belongsToTownHall = false;
      vm.selectedTownhall = undefined;
      vm.isEntitySelectable = true;
      if (angular.isDefined(vm.entities)) {
        if (vm.entities.length === 1) {
          vm.selectedEntity = angular.copy(vm.entities[0]);
          vm.isEntitySelectable = false;
        }
      }
    }


    function parseDate(date) {
      return moment(date).format('YYYY-MM-DD');
    }

    vm.saveReferee = function () {
      DuplicatedPerson.query({
        name: vm.person.name,
        surname: vm.person.surname,
        bornDate: parseDate(vm.person.bornDate)
      }, function (data) {
        if (data.length > 0) {
          $mdDialog.show({
            controller: 'DuplicateDialogCtrl',
            templateUrl: 'home/persons/new-person/duplicate-dialog.tpl.html',
            parent: angular.element(document.body),
            clickOutsideToClose: false,
            locals: {
              duplicatedPersons: data,
              licenses: licenseTypes,
              sports: sports
            }
          }).then(function (personId) {
            if (angular.isDefined(personId)) {
              assignLicenceToPerson(personId);
            } else {
              vm.createPerson();
            }
          });
        } else {
          vm.createPerson();
        }
      }, function (err) {
      });
    };


    function assignLicenceToPerson(personId) {
      var organisationId = getSelectedOrganisationId();
      PersonLicense.save( {personId: personId, team: false}, {licenceTypeId: 4, organisationId: organisationId, iban: null},
        vm.successfulPersonAddition,
      function (err) {

      });
    }

    function getSelectedOrganisationId() {
      if (vm.belongsToCouncil) return null;
      if (vm.belongsToTownHall) return vm.selectedTownhall.id;
      else return vm.selectedEntity.id;
    }





    vm.createPerson = function () {
      var newRefereeLicensePostObject = {
        licenceTypeId: 4,
        organisationId: getSelectedOrganisationId(),
        iban: vm.person.iban,
        person: {
          name: vm.person.name,
          surname: vm.person.surname,
          secondSurname: vm.person.secondSurname,
          genderId: vm.person.genderId,
          bornDate: parseDate(vm.person.bornDate),
          nif: vm.person.nif,
          healthInsuranceCardNumber: vm.person.healthInsuranceCardNumber,
          email: vm.person.email,
          address: vm.person.address,
          town: vm.person.town,
          zipCode: vm.person.zipCode
        }
      };
      RefereeLicence.save(newRefereeLicensePostObject, vm.successfulPersonAddition, vm.errorInPersonAddition);
    };


    vm.successfulPersonAddition = function(){
      $state.go('home.referees', {reload: true});
      $mdToast.show(
        $mdToast.simple()
        .textContent($filter('translate')('REFEREES.SUCCESSFUL_CREATE'))
        .position('bottom left')
        .hideDelay(3000)
      );
    };

    vm.errorInPersonAddition = function(err){
      var errorMessage;
      if (err.status === 400) {
        errorMessage = err.data.message || 'BAD_REQUEST_PERSON';
      } else if (err.status === 409) {
        errorMessage = 'PEOPLE.EXISTING_PERSON';
      } else {
        errorMessage = 'SERVER_ERROR';
      }
      $mdToast.show(
        $mdToast.simple()
        .textContent($filter('translate')(errorMessage))
        .position('bottom left')
        .hideDelay(3000)
      );
    };
  }
}());
